import React from 'react';
import styled from 'styled-components';
import { lifecycle } from 'recompose';

import { getDeviceType } from '../utils/deviceDetector';
import device from '../device';
import { withTheme } from '../components/ui';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Image } from '../components/landingPage';

const LandingPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @media ${device.laptop} {
        flex-direction: row;
    }
`;

const ImageContainer = styled.div`
    @media ${device.tablet} {
        margin-bottom: 2rem;
    }

    @media ${device.laptop} {
        width: 50%;
        margin: 0 auto;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;

    @media ${device.laptop} {
        width: 40%;
    }
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
    color: ${({ primary }: { primary: string }) => primary};
    margin-bottom: 1rem;

    @media ${device.tablet} {
        font-size: 4rem;
    }

    @media ${device.tabletL} {
        font-size: 5rem;
    }

    @media ${device.laptop} {
        font-size: 4rem;
        line-height: 5rem;
    }
`;

const LandingPage = withTheme(props => (
    <Layout {...props} {...{ footer: { contactUs: { title: 'לשיעור ניסיון השאירי פרטים' } } }}>
        <SEO
            title="סטודיו מיס פיטנס | חוגים לנשים | עיצוב | פילאטיס | זומבה | TRX"
            keywords={[
                `פילאטיס בראשון לציון`,
                `זומבה בראשון לציון`,
                `עיצוב וחיטוב בראשון לציון`,
                `סטודיו לנשים בראשון לציון`,
            ]}
        />
        <LandingPageContainer>
            <ImageContainer>
                <Image />
            </ImageContainer>
            <TextContainer>
                <Title {...props.theme}>סטודיו לנשים חזקות</Title>
            </TextContainer>
        </LandingPageContainer>
    </Layout>
));

export default lifecycle({
    componentDidMount() {
        fbq('track', 'Lead', { content_name: getDeviceType() });
    },
})(LandingPage);
